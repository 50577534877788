<template>
  <section class="section-hero">
    <div class="section-hero_container main_sections_container">
      <h1 class="main_title">
        Manage your Shopping <br class="break_main_title" />
        Lists <br class="break_main_title_third" />
        and Track your <br class="break_main_title_secondary" />Expenses.
      </h1>
      <h3 class="main_subtitle">
        Makèt helps you plan and organize your Shoppings for events such as
        Monthly Groceries, Family Functions, Wedding Parties, and so much more.
        The sky is the limit.
      </h3>
      <DownloadBadge />
      <div class="section-hero_image_wrapper">
        <figure
          :style="'background-image: url(' + getImageFn('hero') + ')'"
          class="section-hero_image main_sections_image"
          title="Shopping Cards - Smart Cards - Set item price - Shopping Lists - List's Item - Stores - Best Free South Africa Shopping List Manager App"
        ></figure>
      </div>
    </div>
  </section>
</template>

<script>
import DownloadBadge from "./common/DownloadBadge.vue";
export default {
  name: "Hero",
  components: {
    DownloadBadge,
  },
  props: {
    getImageFn: Function,
  },
};
</script>

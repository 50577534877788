<template>
  <section class="section-cards">
    <div class="section-cards_container d_section-container flex-display">
      <div
        class="card_title box-content d_title flex-one bg-red box-content-margin flex-display flex-item-center"
      >
        <h2 class="large-title">{{ title }}</h2>
      </div>
      <div
        class="card_images box-content d_images flex-one flex-display wrap-flex flex-justify-content-center flex-item-center"
      >
        <div class="card_sub_title d_sub_title box-content-subtitle-container">
          <h3 class="secondary-large-title">{{ subtitle }}</h3>
        </div>
        <div
          class="card_sub_image_content d_sub_image_content flex-display flex-justify-content-center flex-item-center"
        >
          <div class="box-content-margin">
            <img
              title="Best South Africa Shopping and Grocery List App"
              class="response-image"
              :src="getImageFn(cardImage)"
              :alt="
                '- Best Free South Africa Shopping List Manager - shopping cards, loyalty cards, store cards' +
                cardImage
              "
            />
          </div>
          <div>
            <img
              class="response-image"
              :src="getImageFn(presentToTillImage)"
              :alt="presentToTillImage"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Cards",
  data() {
    return {
      title: "All your Smart Cards in ONE Place. Say “NO” to Bumpy Wallet.",
      subtitle: "Simply tap on the Store’s Card and present it to the Cashier.",
      cardImage: "cards",
      presentToTillImage: "present_to_till",
    };
  },
  props: {
    getImageFn: Function,
  },
};
</script>

<template>
  <img
    class="maket_logo"
    src="../../assets/images/maket_logo.png"
    alt="Best Free South Africa Shopping and Grocery List App - Easily Manage your Grocery List - Shopping Assistant"
    title="Best South Africa Shopping and Grocery List App"
  />
</template>

<script>
export default {
  name: "MaketLogo",
};
</script>

<template>
  <section class="section-stores">
    <div class="section-stores_container main_sections_container">
      <div
        class="section-stores_title flex-display flex-justify-content-center flex-item-center wrap-flex"
      >
        <h1 class="main_title">{{ title }}</h1>
        <h3 class="main_subtitle">{{ subtitle }}</h3>
      </div>
      <div class="section-stores_image_wrapper">
        <figure
          :style="'background-image: url(' + getImageFn('stores') + ')'"
          class="stores_images main_sections_image"
          title="Best Free South Africa Shopping and Grocery List App - Grocery List App - Shopping Assistant"
        ></figure>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Stores",
  data() {
    return {
      title: "Where did you buy the Item ?",
      subtitle:
        "Set a store to an item. This helps group your items by Stores, so that you can see the total you've spent at each one.",
    };
  },
  props: {
    getImageFn: Function,
  },
};
</script>

<template>
  <section class="section-features">
    <div
      class="section-features_container flex-display flex-justify-content-center"
    >
      <div
        :class="`section-features_block box-content-margin box-content flex-display flex-justify-content-center flex-one wrap-flex feature_${feature.image}`"
        v-for="feature in features"
        :key="feature.image"
      >
        <div class="feature_title">
          <h2 class="main-secondary-title text-align-center">
            {{ feature.title }}
          </h2>
        </div>
        <div class="feature_description box-content-subtitle-container">
          <p class="main-secondary-subtitle">{{ feature.description }}</p>
        </div>
        <div
          class="feature_image flex-display flex-justify-content-center flex-item-center"
        >
          <img
            class="margin-top-auto box-content-image-size-90"
            :src="getImageFn(feature.image)"
            :alt="feature.image + ' - ' + this.alt"
            title="Best South Africa Shopping and Grocery List App"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Features",
  data() {
    return {
      features: [
        {
          title: "Your Lists",
          description:
            "All your Shopping Lists at your fingertip. Browse old and new Lists with ease.",
          image: "lists",
        },
        {
          title: "List’s Items",
          description:
            "At a glance, visualize each item’s price and quantity. The total spent is at the bottom center. Group your items by categories or stores.",
          image: "list",
        },
        {
          title: "Item’s Price",
          description:
            "Effortless, tap an item, then from the new popup, type in a price,  select a quantity, and optionally, set the store you are buying the item at.",
          image: "set_item_price",
        },
      ],
      alt: "Best Free South Africa Shopping and Grocery List App - Grocery List App - Shopping Assistant",
    };
  },
  props: {
    getImageFn: Function,
  },
};
</script>

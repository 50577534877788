<template>
  <section class="section-download">
    <div class="section-download_container section_container">
      <div
        class="section-download_content box-content flex-display flex-justify-content-center flex-item-center flex-direction-column"
      >
        <h3 class="main_title text-align-center">Download Makèt</h3>
        <DownloadBadge />
        <p class="secondary-main-title margin-top-20">
          More features coming soon.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import DownloadBadge from "../components/common/DownloadBadge";
export default {
  name: "Download",
  components: { DownloadBadge },
};
</script>

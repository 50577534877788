<template>
  <section class="section-profile">
    <div class="section-profile_container flex-display">
      <div
        class="section-profile_text box-content flex-two box-content-margin flex-display flex-item-center flex-justify-content-center"
      >
        <p class="text-align-center main_title">
          All your stats in one place. See how many undone and done Lists,
          Items, and Smart Cards you do have. Tap each count to see more.
        </p>
      </div>
      <div
        class="section-profile_image box-content flex-display flex-one flex-item-center bg-blue flex-direction-column"
      >
        <div class="profile_title box-content-subtitle-container">
          <h3 class="secondary-large-title color-white">
            Use options such as “Share Makèt with Loved ones” to easily let
            loved ones know about Makèt, and “Privacy Policy” to learn more
            about how we use and store your data.
          </h3>
        </div>
        <div class="profile_image">
          <img
            class="response-image"
            :src="getImageFn('profile')"
            alt="Best Free South Africa Shopping and Grocery List App - Grocery List App - Shopping Assistant"
            title="Best South Africa Shopping and Grocery List App"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  Name: "Profile",
  props: {
    getImageFn: Function,
  },
};
</script>

<template>
  <div class="forward_to_store">
    <MaketLogo />
    <h1>Loading, please wait ...</h1>
  </div>
</template>

<script>
import MaketLogo from "../components/common/MaketLogo.vue";

export default {
  name: "StoreListingRedirection",
  data() {
    return {
      platformIos: "ios",
      platformAndroid: "android",
      isoStoreUrl:
        "https://apps.apple.com/za/app/mak%C3%A8t/id1603683020?itsct=apps_box_link&itscg=30200",
      androidStoreUrl:
        "https://play.google.com/store/apps/details?id=co.za.maket.maket",
      invalidPlatformMessage:
        "Sorry! Makèt is not compatible with your device.",
    };
  },
  components: { MaketLogo },
  methods: {
    getUserPlatform() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return this.platformAndroid;
      }

      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return this.platformIos;
      }

      return "unknown";
    },
    getMaketStoreUrl(platform) {
      return platform === this.platformIos
        ? this.isoStoreUrl
        : this.androidStoreUrl;
    },
    isValidPlatform(platform) {
      return [this.platformIos, this.platformAndroid].indexOf(platform) >= 0;
    },
  },
  mounted() {
    const platform = this.getUserPlatform();

    if (this.isValidPlatform(platform)) {
      window.location.href = this.getMaketStoreUrl(platform);
    } else {
      const indicatorText = document.querySelector("h1");
      indicatorText.innerHTML = this.invalidPlatformMessage;
      indicatorText.classList.add("danger");
    }
  },
};
</script>

<style lang="css">
div.forward_to_store {
  background-color: #000;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

div.forward_to_store > img {
  width: 100px;
  height: 100px;
  margin-bottom: 25px;
  border-radius: 15px;
  border: 1px solid rgb(95, 95, 95);
}

div.forward_to_store > h1 {
  color: #f8f8f8;
}

div.forward_to_store > h1.danger {
  color: #ff3535 !important;
}
</style>

<template>
  <section class="section-item_grouping">
    <div
      class="section-item_grouping_container d_section-container flex-display"
    >
      <div
        class="item_grouping_title box-content d_title flex-one bg-pink box-content-margin wrap-flex flex-display flex-item-center"
      >
        <h2 class="large-title">{{ title }}</h2>
      </div>
      <div
        class="item_grouping_images box-content d_images flex-one flex-display wrap-flex flex-justify-content-center flex-item-center"
      >
        <div
          class="item_grouping_sub_title d_sub_title box-content-subtitle-container"
        >
          <h3 class="secondary-large-title">{{ subtitle }}</h3>
        </div>
        <div
          class="item_grouping_sub_image_content d_sub_image_content flex-display flex-justify-content-center flex-item-center"
        >
          <div class="box-content-margin">
            <img
              title="Best South Africa Shopping and Grocery List App"
              class="response-image"
              :src="getImageFn(byCategories)"
              :alt="
                'Best Free South Africa Shopping and Grocery List App - Grocery List App - Shopping Assistant' +
                byCategories
              "
            />
          </div>
          <div>
            <img
              class="response-image"
              :src="getImageFn(byStore)"
              :alt="
                'Best Free South Africa Shopping and Grocery List App - Grocery List App - Shopping Assistant' +
                byStore
              "
              title="Best South Africa Shopping and Grocery List App"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ItemGrouping",
  data() {
    return {
      title: "Group your Items either by Categories or Stores",
      subtitle: "Tap either Categories or Stores, to group your items.",
      byStore: "list_group_store",
      byCategories: "list_group_category",
    };
  },
  props: {
    getImageFn: Function,
  },
};
</script>

<template>
  <section class="section-maket_logo">
    <MaketLogo />
    <img
      @click="changeBrightnessFn"
      class="brightness_switch"
      :src="getBrightnessIconFn()"
      alt="brightness switch"
    />
  </section>
</template>

<script>
import MaketLogo from "../components/common/MaketLogo.vue";

export default {
  name: "HeroLogo",
  components: { MaketLogo },
  props: {
    changeBrightnessFn: Function,
    getBrightnessIconFn: Function,
  },
};
</script>

import { createApp, h } from "vue";
import Home from "./views/Home.vue";
import StoreListingRedirection from "./views/StoreListingRedirection.vue";

const routes = {
  "/": Home,
  "/download": StoreListingRedirection,
};

const MaketApp = {
  data() {
    return {
      currentRoute: window.location.pathname,
    };
  },

  computed: {
    CurrentComponent() {
      return routes[this.currentRoute] || Home;
    },
  },

  render() {
    return h(this.CurrentComponent);
  },
};

createApp(MaketApp).mount("#app");

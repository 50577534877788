<template>
  <section class="section-footer">
    <div
      class="section-footer_container flex-display flex-item-center flex-direction-column"
    >
      <div class="footer_social_network flex-display">
        <a
          href="https://twitter.com/Maket_App?search=Best Free South Africa Shopping and Grocery List App - Grocery List App - Shopping Assistant"
          target="_blank"
          ><img
            class="response-image"
            src="../assets/images/social/twitter.png"
            alt="Visit makèt app on Twitter - Best Free South Africa Shopping and Grocery List App - Grocery List App - Shopping Assistant"
            title="Best South Africa Shopping and Grocery List App"
        /></a>
      </div>
      <div
        class="footer_more_info flex-display margin-top-20 wrap-flex flex-item-center flex-justify-content-center"
      >
        <p>&copy;{{ getYear() }} Makèt</p>
        <p>All Rights Reserved</p>
        <p>
          <a href="/terms.html" target="_blank">Terms and Conditions</a>
        </p>
        <p>
          <a href="/privacy.html" target="_blank">Privacy Policy</a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    getYear() {
      const startYear = 2021;
      const currentYear = new Date().getUTCFullYear();
      return currentYear > startYear
        ? startYear + "-" + currentYear
        : currentYear;
    },
  },
};
</script>

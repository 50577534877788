<template>
  <div class="download_badges">
    <a
      href="https://apps.apple.com/za/app/mak%C3%A8t/id1603683020?itsct=apps_box_badge&amp;itscg=30200"
      style="display: inline-block; overflow: hidden"
      target="_blank"
      class="ios_badge"
      ><img
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1641945600&h=168d3d34b1d848b7b277d2b09c78d296"
        alt="Download Makèt on the App Store"
    /></a>
    <a
      href="https://play.google.com/store/apps/details?id=co.za.maket.maket"
      target="_blank"
      ><img
        class="google_badge"
        src="../../assets/images/external/download_badges/google.png"
        alt="get Makèt on the Google play store, a shopping lists - groceries assistant"
    /></a>
  </div>
</template>

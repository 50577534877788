<template>
  <HeroLogo
    :changeBrightnessFn="changeBrightness"
    :getBrightnessIconFn="getBrightnessIcon"
  ></HeroLogo>
  <Hero :getImageFn="getImage"></Hero>
  <Features :getImageFn="getImage"></Features>
  <Cards :getImageFn="getImage"></Cards>
  <Stores :getImageFn="getImage"></Stores>
  <ItemGrouping :getImageFn="getImage"></ItemGrouping>
  <Profile :getImageFn="getImage"></Profile>
  <Download />
  <Footer />
</template>

<script>
import "../assets/css/style.scss";
import Hero from "../components/Hero.vue";
import Features from "../components/Features.vue";
import Cards from "../components/Cards.vue";
import Stores from "../components/Stores.vue";
import ItemGrouping from "../components/ItemGrouping.vue";
import Profile from "../components/Profile.vue";
import Download from "../components/Download.vue";
import Footer from "../components/Footer.vue";
import HeroLogo from "../components/HeroLogo.vue";

export default {
  name: "Home",
  data() {
    return {
      brightness: "light",
    };
  },
  components: {
    HeroLogo,
    Hero,
    Features,
    Cards,
    Stores,
    ItemGrouping,
    Profile,
    Download,
    Footer,
  },
  methods: {
    getImage(name) {
      return require("../assets/images/mockup/" +
        name +
        "_" +
        this.brightness +
        ".png");
    },
    getBrightnessIcon() {
      const icon = this.getBrightness();
      return require("../assets/images/brightness/" + icon + ".png");
    },
    changeBrightness() {
      this.brightness = this.getBrightness();
      document.querySelector("#body").classList = [this.brightness];
    },
    getBrightness() {
      return this.brightness === "dark" ? "light" : "dark";
    },
  },
};
</script>
